<template>
  <!-- 新 - 首页 - 商务合作 -->
  <div class="businessCooperation gotop">
    <headPageNew activeName="4"></headPageNew>
    <div class="banner"></div>
    <div class="main">
      <h3>合作内容</h3>
      <div class="cooperationContent">
        <div class="ccFirstRow">
          <div class="ccInner">
            <img src="../../assets/loginIndexNew/cooperationContentImg1.png" alt="">
            <div>
              <h4>市场拓展</h4>
              <p>寻求优质合作伙伴，推广安知平台及服务</p>
            </div>
          </div>
          <div class="ccInner">
            <img src="../../assets/loginIndexNew/cooperationContentImg2.png" alt="">
            <div>
              <h4>培训业务</h4>
              <p>企业或培训机构入驻安知平台，开展在线培训业务</p>
            </div>

          </div>
          <div class="ccInner">
            <img src="../../assets/loginIndexNew/cooperationContentImg3.png" alt="">
            <div>
              <h4>课程资源合作</h4>
              <p>诚邀优质机构、讲师等资源合作，打造精品内容</p>
            </div>
          </div>
          <div class="ccInner">
            <img src="../../assets/loginIndexNew/cooperationContentImg4.png" alt="">
            <div>
              <h4>人力资源服务</h4>
              <p>为企业提供咨询、招聘、内训等人力资源服务</p>
            </div>
          </div>
          <div class="ccInner">
            <img src="../../assets/loginIndexNew/cooperationContentImg5.png" alt="">
            <div>
              <h4>数字服务</h4>
              <p>面向企业或培训机构提供定制化开发服务</p>
            </div>
          </div>
        </div>
<!--        <div class="ccSecondRow">-->

<!--        </div>-->
      </div>
    </div>
    <div class="main main1">
      <h3>合作优势</h3>
      <div class="cooperationAdvantages">
        <div class="caInner">
          <img src="../../assets/loginIndexNew/cooperationAdvantagesImg1.png" alt="">
          <h4>返利支持</h4>
          <p>高额返利体系</p>
        </div>
        <div class="caInner">
          <img src="../../assets/loginIndexNew/cooperationAdvantagesImg2.png" alt="">
          <h4>技术支持</h4>
          <p>系统快速迭代，<br>定期线上产品培训</p>
        </div>
        <div class="caInner">
          <img src="../../assets/loginIndexNew/cooperationAdvantagesImg3.png" alt="">
          <h4>营销支持</h4>
          <p>品牌宣传联动、活动推广扶持、<br>产品宣传册等物料、<br>拓客话术等销售资料</p>
        </div>
        <div class="caInner">
          <img src="../../assets/loginIndexNew/cooperationAdvantagesImg4.png" alt="">
          <h4>服务支持</h4>
          <p>专业的客户服务团队、<br>完善的售后客服系统、<br>7x12 小时在线人工客服</p>
        </div>
      </div>
    </div>
    <bottomPage />
    <hz/>
  </div>
</template>

<script>
import headPageNew from "../wrongAuthenticatePage/headPageNew.vue";
import bottomPage from "@/views/wrongAuthenticatePage/bottomPage";

import hz from "../wrongAuthenticatePage/hz.vue";
export default {
  name: "businessCooperation",
  components: {
    headPageNew,
    bottomPage,

    hz
  },
  data() {
    return {
    }
  },
  methods:{
  }
}
</script>

<style lang="less" scoped>
.businessCooperation{
  margin-top: 62px;
  background-color: #F9FAFC;
  height: 100%;
  overflow-y: scroll;
  &::-webkit-scrollbar { width: 0 !important }
}

.banner{
  height: 300px;
  background-size: cover;
  background-position: center;
  background-image: url("../../assets/loginIndexNew/banner3.png");
}
.main{
  position: relative;
  width: 1440px;
  margin: 0 auto;
  h3{
    position: relative;
    text-align: center;
    padding: 40px 0 40px;
    color: #333;
    font-size: 30px;
    font-weight: bold;
  }
}
.main1{
  &:before{
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100vw;
    height: 100%;
    background: #FFF;
  }
}
.ccFirstRow{
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 60px;
  .ccInner{
    flex: 1;
    margin-right: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 12px;
    transition: 0.5s;
    padding: 0 40px 34px;
    &:last-child{
      margin-right: 0;
    }
    &:hover{
      box-shadow: 0px 10px 30px 1px rgba(224,228,238,0.5);
    }
    img{
      width: 100px!important;
      height: 100px!important;
      margin-top: -50px;
      margin-bottom: 20px;
    }
    h4{
      text-align: center;
      font-size: 24px;
      color: #333;
      margin-bottom: 7px;
    }
    p{
      text-align: center;
      font-size: 16px;
      line-height: 1.4;
      color: #666;
    }
  }
}
.cooperationAdvantages{
  display: flex;
  position: relative;
  .caInner{
    flex: 1;
    margin-right: 30px;
    height: 400px;
    margin-bottom: 60px;
    background: linear-gradient(180deg, #F3F5F8 0%, #FAFAFA 100%);
    transition: 0.5s;
    border-radius: 12px;
    text-align: center;
    &:hover{
      background: linear-gradient(180deg, #EFF6FF 0%, #F7F8FF 100%);
      box-shadow: 0px 10px 30px 1px rgba(224,228,238,0.5);
    }
    &:last-child{
      margin-right: 0;
    }
    img{
      width: 180px!important;
      height: 180px!important;
      margin-bottom: 10px;
      margin-top: 48px;
    }
    h4{
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 10px;
      color: #333;
    }
    p{
      font-size: 16px;
      line-height: 1.4;
      color: #666;
    }
  }
}

@media (max-width: 1500px) {
  .main{
    width: 1200px;
  }
  .banner{
    height: 250px;
  }

  .ccFirstRow .ccInner{
    padding:0 20px 34px;
  }

}
.clearfix:after{
  content:"";//设置内容为空
  height:0;//高度为0
  line-height:0;//行高为0
  display:block;//将文本转为块级元素
  visibility:hidden;//将元素隐藏
  clear:both;//清除浮动
}
.clearfix{
  zoom:1;//为了兼容IE
}
</style>